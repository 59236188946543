import Vue from 'vue';
import router from '../../router/index';
import store from '../../store/index';
import config from '../../config';

let fetchWrapper = {
  fetch: async (url, params = { method: 'GET' }) => {
    params.headers = {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('access_token')
    };
    params.credentials = 'include';

    const res = await fetch(url, params);
    if (res.status === 401) {
      fetch(`${config.URL}/refreshtoken`, { method: 'POST', credentials: 'include' }).then(refreshRes => {
        if (refreshRes.status === 200) {
          refreshRes.json().then(data => {
            const { accessToken } = data;
            store.commit('refreshAccessToken', accessToken);
          });
        } else {
          store.commit('disconnectUser');
          router.push('/');
          return;
        }
      });
      return;
    } else if (res.status === 403) {
      store.commit('disconnectUser');
      router.push('/');
      return;
    } else if (res.status === 404) {
      return res;
    } else if (res.status === 200) {
      return res;
    }
  }
};

export default fetchWrapper;
